export default {
  'Console': 'סלי קניות',

  'Carts': 'סלי קניות',
  'Orders': 'הזמנות',
  'Customers': 'לקוחות',
  'Products': 'מוצרים',
  'Disconnected': 'התנתק',
  'My Account': 'חשבון שלי',
  'Dark': 'אפל',
  'Light': 'אור',
  'Logout': 'ניתוק',
  'Point of Sale': 'POS',
  'Customer': 'לקוח',
  'Simple': 'פשוט',
  'Labeled': 'תוויות',
  'Confirm': 'לאשר',
  'Subtotal': 'סך ביניים',
  'Discounts': 'הנחות',
  'Taxes': 'מסים',
  'Total': 'סה״כ',
  'Order': 'הזמנה',
  'E-mail': 'מייל',
  'Shipping Address': 'כתובת למשלוח',
  'Billing Address': 'כתובת חשבון',
  'Save': 'לשמור',
  'Reset': 'איתחול',
  'Summary': 'סיכום',
  'Delete': 'למחוק',
  'Update': 'לעדכן',
  'Items': 'פריטים',
  "Yes, that's fine": 'כן, להמשיך',
  'Danger Zone': 'איזור מסוכן',
  'Please Confirm': 'נא לאשר',
  'Price': 'מחיר',
  'Sale Price': 'מחיר מבצע',
  'Yes': 'כן',
  'No': 'לא',
  'Add': 'להוסיף',
  'Submit': 'שלח',
  'Created From': 'נוצר ב',
  'Creation Source': 'מקור יצירה',
  'General': 'כללי',
  'Login': 'כניסה',
  'Password': 'סיסמה',
  'Forgot Password?': 'שכחת סיסמה?',
  'Send Reset Link': 'שלח קישור איתחול סיסמה',
  'New Password': 'סיסמה חדשה',
  'Reset Password': 'לשחזר סיסמה',
  'Password Confirmation': 'אישור סיסמה',
  'Account saved': 'חשבון שמור',
  'First Name': 'שם פרטי',
  'Last Name': 'שם משפחה',
  'Date of Birth': 'תאריך לידה',
  'Date Of Birth': 'תאריך לידה',
  'Telephone': 'מספר טלפון',
  'Street': 'רחוב',
  'City': 'עיר',
  'State': 'מדינה',
  'Country': 'ארץ',
  'Post code': 'מיקוד',
  'Company': 'חברה',
  'Available': 'זמין',
  'Availability': 'זמינות',
  'Min per order': 'מינימום להזמנה',
  'Max per order': 'מקסימום להזמנה',
  'In Stock': 'במלאי',
  'Policy': 'מדיניות',
  'Quantity': 'כמות',
  'continue': 'להמשיך',
  'stop': 'עצור',
  'Track Inventory': 'ניהול מלאי',
  'Restockable Item': 'ניתן להוסיף למלאי',
  'Product Type': 'סוג מוצר',
  'physical': 'פיסי',
  'digital': 'דיגיטלי',
  'downloadable': 'להורדה',
  'Create Order': 'ליצור הזמנה',
  'Quotes': 'הצעות מחיר',
  'Create': 'ליצור',
  'Create Quote': 'ליצור הצעת מחיר',
  'Place Order': 'לבצע הזמנה',
  'Hold': 'בשהייה',
  'Invoice': 'חשבונית',
  'Add new Customer': 'להוסיף לקוח חדש',
  'Cancel': 'ביטול',
  'Release': 'לשחרר',
  'All': 'הכל',
  'All Categories': 'כל הקטגוריות',
  'Note Type': 'סוג הערה',
  'Add Note': 'להוסיף הערה',
  'Simple Note': 'הערה פשוטה',
  'Label': 'תווית',
  'Values': 'ערך',
  'Add Product': 'להוסיף מוצר',
  'Added Items': 'פריטים שנוספו',
  'Note': 'הערה',
  'Shipping Method': 'שיטת משלוח',
  'Coupon': 'קופון',
  'Code': 'קוד',
  'Add Coupon': 'להוסיף קופון',
  'Apply': 'להחיל',
  'Applied': 'הוחל',
  'Variants': 'שונות',
  'Edit': 'לערוך',
  'Search Products by name': 'חיפוש מוצר לפי שם',
  'Details': 'פרטים',
  'Set As Guest': 'להגדיר כאורח',
  'Search by product name': 'חיפוש לפי שם המוצר',
  'Search by last name': 'חיפוש לפי שם משפחה',
  'Create Customer and Save': 'ליצור לקוח ולשמור',
  'Fill Address': 'למלא כתובת',
  'productsPagination ': '',
  'Coupon Code': 'קוד קופון',
  'Nothing to show': 'אין מה להציג',
  'Customer Details': 'פרטי לקוח',
  'Order Note': 'הערת הזמנה',
  'Properties': 'אפיינים',
  'Product': 'מוצר',
  'Select Payment Method': 'תבחרו שיטת תשלום',
  'Place': 'מקום',
  'Set Method': '',
  'Required!': 'חובה!',
  'Notes': 'הערות',
  'No products to show': 'אין מוצרים להציג',
  'Draft Order Saved': 'טיוטת הזמנה שמורה',
  'Cannot find order created from this Cart. Redirecting to Orders list.': '',
  'There are no shipping methods available.': 'אין שיטות משלוח',
  'Refund': 'החזר',
  'Post': '',
  'Status': 'סטטוס',
  'Archive': '',
  'Ship Items': 'שליחת מוצרים',
  'Mark as Paid': 'סמן כשולם',
  'guest': 'אורח',
  'processing': 'בתהליך ביצוע',
  'approved': 'מאושר',
  'shipped': 'נשלח',
  'partially shipped': 'נשלח חלקית',
  'partially_shipped': 'נשלח חלקית',
  'on_hold': 'בשהייה',
  'refunded': 'הוחזר',
  'canceled': 'מבוטל',
  'completed': 'הושלם',
  'unfulfilled': 'לא מומשה',
  'Leave a comment...': 'השאירו תגובה…',
  'Unit Price': 'מחיר פריט',
  'Row Total': 'סה״כ לשורה',
  'Hold Reasons': 'סיבות השהייה',
  'Guest': 'אורח',
  'Payment Method': 'שיטת תשלום',
  'Payment from': 'תשלום מ:',
  'via': 'דרך',
  'Timeline': '',
  'Shipment': 'משלוח',
  'Carrier': 'חברת משלוחים',
  'Tracking number': 'מספר מעקב',
  'Please select items to refund': 'נא לבחור פריטים להחזר',
  'Please select refund options': 'נא לבחור שיטת החזר',
  'Notify Customer': 'להודיע ללקוח',
  'Re-stock items': 'לחזיר מוצרים למלאי',
  'Refund Items': 'להחזר פריטים',
  'Refund order': 'להחזר הזמנה',
  'Added shipment': 'משלוח נוספת',
  'Order marked as paid': 'הזמנה מסומנת כשולם',
  'Put on hold': 'להשהות',
  'Are you sure that you want to mark this order as paid?': 'לסמן את ההזמנה כשולם?',
  'markOrderAsPaid': 'סמןהזמנהכשולם',
  'Hold Reason': 'סיבת השהייה',
  'Please write the hold reason': 'נא לציין סיבת ההשהייה',
  'Created By': 'מיוצר ע״י',
  'unlimited': 'ללא הגבלה',
  'Inventory': 'מלאי',
  'Variants Options': '',
  'Images': 'תמונות',
  'Slug': 'כתובת אתר',
  'Taxonomy': 'טקסונומיה',
  'Brand': 'מותג',
  'Description': 'תיאור',
  'Tags': 'תגים',
  'Product Variations': 'שונות מוצר',
  'Search Variation': 'חיפוש שונות',
  'No properties': 'אין אפיינים',
  'No Variants': 'אין שונות',
  'Policy (allows customer to purchase out of stock item)': '',
  'Personal Information': 'מידע פרטי',
  'Customer Group': 'קבוצת לקוחות',
  'New Customer': 'לקוח חדש',
  'Subscription': 'מנוי',
  'Group': 'קבוצה',
  'Addresses': 'כתובות',
  'Search Address': 'חיפוש כתובות',
  'Add New': 'להוסיף חדש',
  'No orders yet for this customer': 'אין הזמנות עבור הלקוח',
  'Default': 'ברירת מחדל',
  'View Order': 'צפה בהזמנה',
  'Place Another Order': 'לבצע הזמנה נוספת',
  'Print Invoice': 'הדפס חשבון',
  'INVOICE NO.': 'חשבון מס.',
  'ORDER DATE': 'תאריך הזמנה',
  'PAYMENT': 'תשלום',
  'SHIPPING': 'משלוח',
  'BILL TO': 'כתובת חשבון',
  'SHIP TO': 'כתובת משלוח',
  'ITEM DESCRIPTION': 'תיאור מוצר',
  'QTY': 'כמות',
  'PRICE': 'מחיר',
  'TOTAL': 'סה״כ',
  'Customer Note': 'הערת לקוח',
  'Discount': 'הנחה',
  'Sales Taxes': 'מע״מ',
  'Shipping': 'דמי משלוח',
  'Thanks for choosing our products!': 'תודה שבחרתם אותנו!',
  'Number': 'מספר',
  'Name': 'שם',
  'Items count': '',
  'ID': 'מס׳',
  'Order Status': 'סטטוס הזמנה',
  'Email': 'מייל',
  'DOB': 'תאריך לידה',
  'Phone': 'טלפון',
  'Image': 'תמונה',
  'SKU': 'מק״ט',
  'Counts': '',
  'Rows per page': 'שורות בעמוד',
  'dataTablePagination': '',
  'Select filter': 'תבחרו מסנן',
  'Filters': 'מסננים',
  'Brands': 'מותגים',
  'Price from low to high': 'מחיר מנמוך עד גבוה',
  'Price from high to low': 'מחיר מגבוה עד נמוך',
  'Name (A-Z)': 'שם (א-ת)',
  'Name (Z-A)': 'שם (ת-א)',
  'Newest': 'הכי חדש',
  'Search cart by user name': 'חיפוש סל קניות לפי שם משתמש',
  'Search customer': 'חיפוש לקוח',
  'Search order by ID': 'חפוש לפי מספר הזמנה',
  'Search product': 'חיפוש מוצר',
  'Sort by': 'למיין',
  'Email (A-Z)': 'מייל (A-Z)',
  'Email (Z-A)': 'מייל (Z-A)',
  'Close filters': 'סגור מסננים',
  'Sales': 'מכירות',
  'Dashboard': 'לוח בקרה',
  'All Customers': 'כל הלקוחות',
  'Groups': 'קבוצות',
  'Catalog': 'קטלוג',
  'Campaigns': 'קמפיינים',
  'Auth': 'אשר',
  'Users': 'משתמשים',
  'Roles': 'תפקידים',
  'Permissions': 'הרשאות',
  'Store Management': 'ניהול חנות',
  'Fullscreen': 'מסך מלא',
  'Updated At': 'תאריך עדכון',
  'Item Count': 'מספר פריטים',
  '{0} unread notifications': '{0} הודאות לא נקראות',
  'Store Settings': 'הגדרות חנות',
  'Site Traffic': 'תנועה באתר',
  'Top Location': '',
  'Conversion': 'המרה',
  'Increase': 'להגדיל',
  'Decrease': 'להקטין',
  'Refunds': 'החזרים',
  'Unique Visit': 'ביקור ייחודי',
  'Page View': 'צפיית עמוד',
  'Other': 'אחר',
  '{0} - {1} of {2}': '{0} - {1} מתוך {2}',
  'Account': 'חשבון',
  'Settings': 'הגדרות',
  'General Settings': 'הגדרות כלליות',
  'Contact Address': 'כתובת איש קשר',
  'Store address used for mailing, store footer and other settings.': 'כתובת חנות עבור דיוור, ה-footer והגדרות נוספות.',
  'Region': 'איזור',
  'Postcode': 'מיקוד',
  'Currency and Locale': 'מטבע ומיקום',
  'Store default currency and default locale. Default currency is used with every resource that has price.':
    'מטבע ומיקום ברירת מחדל. מטבע ברירת מחדל ישתמש עבור כל משאב שיש לו מחיר.',
  'Store Currency': 'מטבע החנות',
  'Store Locale': 'מיקום החנות',
  'Store Brand settings, like logo.': 'הגדרות מותג חנות, לדוגמה לוגו.',
  'All stores': 'כל החנויות',
  'Create new store': 'הוסף חנות חדשה',
  'No stores to display': 'אין חנויות להציג',
  'Change console primary color': 'בחר צבע עבור החנות',
  'Here you can change general setup': 'פה אפשר לשנות התקנה כללית',
  'Here you can change customers settings': 'פה אפשר לשנות הגדרות לקוחות',
  'Payments': 'תשלומים',
  'Here you can setup payments': 'פה אפשר להגדיר תשלומים',
  'Locations': 'מיקומים',
  'Here you can setup localistation': 'פה אפשר להגדיר לוקליזציה',
  'Checkout': 'קופה',
  'Here you can setup checkout actions': 'פה אפשר להגדיר פעולות בקופה',
  'Here you can setup shipping actions': 'פה אפשר להגדיר פעולות משלוחים',
  'Here you can setup notifications': 'פה אפשר להגדיר הודאות',
  'Customers Settings': 'הגדרות לקוחות',
  'Configure customer general settings': 'הגדר הגדרות לקוחות כלליות',
  'Require Customer Date of Birth': 'דרוש תאריך לידה של הלקוח',
  'Customers Groups': 'קבוצות לקוחות',
  'Configure customer groups': 'הגדר קבוצות לקוחות',
  'Customer Default Group': 'קבוצת לקוחות ברירת מחדל',
  'Guest Default Group': 'קבוצת אורחים ברירת מחדל',
  'General Tax Settings': '',
  'Apply tax behaviour for whole store': 'החל הגדרת מס על כל החנות',
  'Source options:': '',
  '"origin" uses store address to calculate taxes': '"מקור" משתמש בכתובת החנות כדי לחשב מיסים',
  'general.settings.taxes.destination_source':
    '"יעד" משתמש בכתובת משלוח לצורך חישוב מיסים. אם סוג המשלוח להזמנה הוא איסוף, השתמש בכתובת החנות.',
  'general.settings.taxes.shipping_type': '',
  'Based on source:': '',
  'Ignore Shipping Type': 'התעלם משיטת משלוח',
  'Tax included in price': 'מס כלול במחיר',
  'Sale tax': 'שיעורי מס',
  'Customize your tax rate names and % rate.': 'התאם אישית את שמות שיעורי המס ושיעור האחוז.',
  'Add sale tax': 'הוסף שיעורי מס',
  'No sale taxes to display': 'אין שיעורי מס להציג',
  'Rate:': 'שיעור:',
  'Close': 'סגור',
  'Sale taxes': 'שיעורי מס',
  'Tax name': 'שם מס',
  'Tax rate': 'שיעור מס',
  'Add more sale taxes': 'הוסף שיעורי מס נוספים',
  'Tax Groups ': 'קבוצות מס',
  'Customize tax groups, their zone codes, taxonomy and attached sale taxes.': '',
  'Add tax group': 'הוסף קבוצת מס',
  'No tax groups to display': 'אין קבוצות מס להציג',
  'Add tax rate first': 'הוסף שיעור מס ראשון',
  'Tax group name': 'שם קבוצת מס',
  'Select sale taxes': 'בחר שיעירי מס',
  'Select customer group': 'בחר קבוצת לקוחות',
  'Shipping is taxable': 'דמי משלוח חייבים במס',
  'Leaving taxonomy field empty will cause tax group to match all products.':
    'השארת שדה הקטגוריות ריק תגרום לקבוצת המס להתאים לכל המוצרים.',
  'Select Taxonomy': 'בחר טקסונומיה',
  'Mass Action': 'פעולה גורפת',
  'Print': 'הדפס',
  'Please write the hold reason:': 'נא לציין סיבת ההשהייה',
  'Timeline Track': '',
  'Created At': 'תאירך ייצור',
  'Resolved At': 'תאריך סגירה',
  'Fulfill order': 'להכין את ההזמנה',
  'SKU:': 'מק״ט:',
  'Ship': 'לשלוח',
  'Mark order {0} as paid': 'סמן הזמנה {0} כשולם',
  'Mark as paid': 'סמן כשולם',
  'Manage': 'נהל',
  'Type, select or create': 'להקליד, לבחור או לייצר',
  'Capture': 'לשלם',
  'Please select items to refund:': 'נא לבחור פריטים להחזר',
  'qty': 'כמות',
  'Please select refund options:': 'נא לבחור אופציות החזר',
  'Notify customer': 'להודיע ללקוח',
  'Refund order {0}': 'זיכוי מספר {0}',
  'Order Summary': 'סיכום הזמנה',
  'items': 'פריטים',
  'Shipped': 'נשלח',
  'Unfulfilled': 'לא מומשה',
  'Shipped by {0}': 'נשלח ע״י {0}',
  'Tax:': 'מס',
  'Item:': 'פריט:',
  'Search customers by first name': 'חיפוש לקוחות לפי שם פרטי',
  'Search products by name or SKU': 'חיפוש מוצרים לפי שם או מק״ט',
  'New Note': 'הערה חדשה',
  'You can either add only simple notes or labeled notes to item': 'אפשר להוסיף רק הערות פשוטות או',
  'Draft Order': 'טיוטת הזמנה',
  'New Order': 'הזמנה חדשה',
  'Order Items': 'פריטים בהזמנה',
  'Order note': 'הערה להזמנה',
  'Shipping and packaging': 'משלוח ואריזה',
  '{0} items': '{0} פריטים',
  'Select Address': 'בחר כתובת',
  'Manage Addresses': 'ניהול כתובות',
  'Add to track': 'הוסף לעקוב משלוח',
  'Comment': 'הערה',
  'Set status': 'הגדר סטטוס',
  'Address': 'כתובת',
  'Get Invoice': 'לחשבון',
  'Tracking Timeline': 'איתור מעקב',
  'Order Delivery Information': 'פרטי מסירה',
  'Total price:': 'סה״כ',
  '{0} in stock': '{0} במלאי',
  'Show More': 'לראות יותר',
  'Show Less': 'לראות פחות',
  'Activity Timeline': '',
  'Add new address': 'הוסף כתובת חדשה',
  'Points': 'נקודות',
  'Total money spent': 'סך הכל שהוצא',
  'Generate password': 'צור סיסמה',
  'Reset password': 'איפוס סיסמה',
  'Password Settings': 'הגדרות סיסמה',
  'Require password change on first login': 'לחייב החלפת סיסמה בכניסה ראשונה',
  'Customer has been requested to change password on first login attempt.': 'הלקוח מבוקש להחליף סיסמה בכניסה ראשונה',
  'Save new password': 'שמור סיסמה חדשה',
  'New password': 'סיסמה חדשה',
  'New password confirmation': 'אישור סיסמה חדשה',
  'Date of birth': 'תאריך לידה',
  'For marketing emails': 'עבור פרסומות מייל',
  'Customer Groups': 'קבוצות לקוחות',
  'Add new Group': 'הוסף קבוצה',
  'Group Name': 'שם קבוצה',
  'Type': 'סוג',
  'Start Date': 'תאריך התחלה',
  'End Date': 'תאריך סיום',
  'Used {0} times': 'שימוש {0} פעמים',
  'Discount type': 'סוג הנחה',
  'Rate type': 'סוג שיעור',
  'Discount Target': 'יעד הנחה',
  'Determines what kind of coupon it is': 'קובע סוג הקופון',
  'Discount Priority': 'עדיפות הנחה',
  'Exclusive': 'בלעדי',
  'Customer Groups allowed to use this promotion. <br> Leave empty to allow everyone.':
    'קבוצות הלקוחות אשר יכולים להשתמש בהנחה. <br> לשמור ריק כדי לאשר לכולם.',
  'Target condition determines on what <br> kind of products the campaign affects':
    'תנאי יעד קובע איזה <br> סוג מוצרים כלולים בקמפיין',
  'Conditions': 'תנאים',
  'If': 'אם',
  'of the following true:': 'מהבאים הם נכונים:',
  'group': 'קבוצה',
  'condition': 'תנאי',
  'Remove group': 'הסר קבוצה',
  'Remove condition': 'הסר תנאי',
  'any': 'כל אחד',
  'all': 'הכל',
  'Discount details': 'פרטי הנחה',
  'Active': 'פעיל',
  'Discount Name': 'שם הנחה',
  'Rate': 'שיעור',
  'Discount Description': 'תיאור הנחה',
  'Active dates': 'תאריכי פעילות',
  'Starts at': 'מתחיל',
  'Ends at': 'מסיים',
  'Coupon code': 'קוד קופון',
  'Maximum uses per customer': 'מקסימום שימושים ללקוח',
  'Maximum total uses': 'מקסימום שימושים',
  'For example HAPPYSALE2019': 'לדוגמה HAPPYSALE2019',
  'Target conditions': 'תנאי יעד',
  'Add target': 'הוסף יעד',
  'Select type of target': 'בחר סוג יעד',
  'Remove target': 'הסר יעד',
  'Product type': 'סוג מוצר',
  "Product's SKU's": 'מק״ט',
  "Product's names": 'שם מוצר',
  "Product's brands": 'מותג',
  'Values, comma separated': 'ערכים נפרדים בפסיק',
  'Add Discount': 'הוסף הנחה',
  'Profile Information': 'פרופיל',
  'Customer ID': 'מספר לקוח',
  'Search': 'חיפוש',
  'User Roles': 'תפקידי משתמש',
  'Add Roles': 'הוסף תפקידים',
  'Add Selected': 'הוסף נבחרים',
  'User Permissions': 'הרשאות משתמש',
  'Add Permissions': 'הוסף הרשאות',
  'Scope': 'הקף',
  'App': 'אפליקציה',
  'Store': 'חנות',
  'Action': 'פעולה',
  'Resource': 'משאב',
  'Role Details': 'פרטי תפקיד',
  'Add new Role': 'הוסף תפקיד חדש',
  'Role Permissions': 'הרשאות תפקיד',
  'Role Users': 'משתמשי תפקיד',
  'Add Users': 'הוסף משתמשים',
  'Add New User': 'הוסיף משתמש חדש',
  'Add new Permission': 'הוסף הרשאה חדשה',
  'Permission Details': 'פרטי הרשאה',
  'Back': 'אחור',
  'Create Product': 'צור מוצר',
  'Export': 'ייצא',
  'Import': 'ייבא',
  'Products Export': 'ייצוא מוצרים',
  'Products Import': 'ייבוא מוצרים',
  'Refund Offline': 'החזר Offline',
  'Include products children in exported file.': 'כלול ילדי מוצר בקובץ ייצוא',
  'Update existing products.': 'עדכן מוצרים',
  'Select...': 'בחר...',
  'online': 'אונליין',
  'Clear': 'מחוק',
  'There is no authorization that can be captured.': 'אין אישור שניתן לתפוס.',
  'Payment captured': 'תשלום נתפס',
  'Upload Preset': 'העלאה הגדרות קבועות',
  'Store Cloudinary credentials (name and preset).': 'שמור פרטי Cloudinary',
  'Cloud Name': 'שם ענן',
  'Products exported. Please save the file.': 'מוצרים יוצאו. נא לשמור את הקובץ.',
  'Products imported.': 'מוצרים יובאו',
  'Bundle': 'חבילה',
  'Add Items': 'הוסף פריטים',
  'Add Item': 'הוסיף פריט',
  'Search in products and variants...': 'חיפוש מוצרים ואופציות',
  'Name and Description': 'שם ותיאור',
  'Insert title...': 'הכנס כותרת ...',
  'slug': 'כתובת',
  'Add Image': 'הוסף תמונה',
  'Default image': 'תמונת ברירת מחדל',
  'Select default image': 'בחר תמונה המוגדרת כברירת מחדל',
  'Default image not selected, placeholder will be used instead':
    'תמונת ברירת המחדל לא נבחרה, במקום זאת ישתמש במציין מיקום',
  'Upload image file': 'העלה קובץ תמונה',
  'Image url': 'כתובת של תמונה',
  'Image url is required': 'כתובת נדרשת',
  'Image url must be valid': 'כתובת של התמונה חייבת להיות תקפה',
  'Select Method': 'בחר שיטה',
  'Save Image': 'שמור תמונה',
  'Restock/Deduct Quantity': 'כמות הוספת / ניכוי מלאי',
  'Restock': 'להוסיף',
  'Deduct': 'לנכות',
  'Allow customers to purchase this product when out of stock': 'אפשר ללקוחות לרכוש מוצר זה כאשר אין במלאי',
  'Track inventory': 'עקוב אחר מלאי',
  'Quantity updated': 'הכמות עודכנה',
  "Quantity won't change when product inventory is not tracked.": 'הכמות לא תשתנה כאשר לא עוקבים אחר מלאי המוצרים.',
  'Add option': 'הוסף אופציה',
  'Generate Variants': 'צור וריאנט',
  'Variants generated': 'וריאנטים שנוצרו',
  'Product Option Removal': 'הסרת אופצית מוצר',
  'This operation will regenerate all variants. Do you want to continue?':
    'פעולה זו תחדש את כל הוריאנטים. האם אתה רוצה להמשיך?',
  'Option deleted': 'האופציה נמחקה',
  'Variant Options': 'אופציות וריאנט',
  'Pricing': 'תמחור',
  'Sale price': 'מחיר מבצע',
  'Add Property': 'הוסף מאפיין',
  'Value': 'ערך',
  'Search engine listing preview': 'תצוגה מקדימה של רשימת חיפוש',
  'Product Variants': 'וריאנטי מוצר',
  'Save and close': 'שמור וסגור',
  'Duplicate': 'לשכפל',
  'Single name': 'שם יחיד',
  'Product count': 'ספירת מוצרים',
  'Full taxonomy': 'טקסונומיה מלאה',
  'Error saving taxonomy': 'שגיאה בשמירת הטקסונומיה',
  '{0} deleted': '{0} נמחק',
  '{0} saved': '{0} נשמר',
  'Error deleting taxonomy': 'שגיאה במחיקת הטקסונומיה',
  'Taxonomies deleted successfully.': 'הטקסונומיות נמחקו בהצלחה.',
  'Create taxonomy': 'צור טקסונומיה',
  'Taxonomy name': 'שם טקסונומיה',
  'Subtaxonomy': 'תת טקסונומיה',
  'Parent taxonomy': 'טקסונומיית הורה',
  'Children taxonomy': 'טקסונומיית ילד',
  'New Taxonomy': 'טקסונומיה חדשה',
  'Allowed Applications': 'אפליקציות מותרות',
  'When application is being selected, this product will become available only for it becoming automatically unavailable for all applications not being selected. When no application is selected, then product is available for all.':
    '',
  'Product Restrictions': 'הגבלות מוצר',
  'New User': 'משתמש חדש',
  'You have unsaved changes. Are you sure want to leave?': 'יש לך שינויים שלא נשמרו. האם אתה רוצה לצאת?',
  'Attention': 'אזהרה',
  'Auth users deleted successfully.': 'משתמשים מאושרים נמחקו בהצלחה.',
  'Order marked as paid.': 'הזמנה מסומנת כשולם.',
  'Auth roles deleted successfully.': 'תפקידים מאושרים נמחקו בהצלחה.',
  'New Role': 'תפקיד חדש',
  'Role created': 'תפקיד נוצר',
  'Role updated': 'התפקיד עודכן',
  'Delete Role': 'מחק תפקיד',
  'Auth permissions deleted successfully.': 'הרשאות המאושרות נמחקו בהצלחה.',
  'Permission': 'הרשאה',
  'New Permission': 'הרשאה חדשה',
  'Permission created': 'ההרשאה נוצרה',
  'Permission updated': 'ההרשאה עודכנה',
  'Delete Permission': 'מחק את ההרשאה',
  'Discount saved': 'ההנחה נשמרה',
  'Campaigns deleted successfully.': 'קמפיינים נמחקו בהצלחה.',
  'Values included': 'ערכים כלולים',
  'Values excluded': 'ערכים לא נכללים',
  "Failed to update customer's user": 'נכשל עדכון משתמש הלקוח',
  'Customer updated': 'הלקוח עודכן',
  'Customers deleted successfully.': 'לקוחות נמחקו בהצלחה.',
  'New Group': 'קבוצה חדשה',
  'Customer Groups deleted successfully.': 'קבוצות לקוחות נמחקו בהצלחה.',
  'Password reset link sent to customer.': 'קישור לאיפוס סיסמה נשלח ללקוח.',
  'New password has been generated.': 'נוצרה סיסמה חדשה.',
  'New password has been saved.': 'סיסמה חדשה נשמרה.',
  'Shipping Discount': 'הנחת משלוח',
  'Orders canceled successfully.': 'ההזמנות בוטלו בהצלחה.',
  'Selected item is already in cart': 'הפריט שנבחר כבר נמצא בסל קניות',
  'Order Discount': 'הנחת הזמנה',
  'Items Discount': 'הנחת פריטים',
  'There is no invoice associated with this order.': 'אין חשבונית המשויכת להזמנה זו.',
  'Order refunded.': 'ההזמנה הוחזרה.',
  'Products deleted successfully.': 'מוצרים נמחקו בהצלחה.',
  'Provide Image url': 'ספק כתובת של תמונה',
  'Quotes deleted successfully.': 'הצעות מחיר נמחקו בהצלחה.',
  'Create your first store': 'צור חנות ראשונה שלך',
  'Add new zone': 'הוסף אזור חדש',
  'Zone Settings': 'הגדרות אזור',
  'Localisation': 'לוקליזציה',
  'Add localisation i.e. country and territories that your store is able to send deliveries':
    'הוסף לוקליזציה כלומר ארץ וטריטוריות שהחנות שלך מסוגלת לשלוח משלוחים',
  'Applied and using methods': 'מיושם ומשתמש בשיטות',
  'Add shipping method': 'הוסף שיטת משלוח',
  'Provide code name': 'ספק שם קוד',
  'Shipping method saved': 'שיטת המשלוח נשמרה',
  'Shipping method has been deleted': 'שיטת המשלוח נמחק',
  'Error deleting shipping method': 'שגיאה במחיקת שיטת המשלוח',
  'Payment methods': 'אמצאי תשלום',
  'Payment method updated': 'אמצע התשלום עודכן',
  'Notifications Settings': 'הגדרות התראות',
  'Configure email "from" settings.': 'הגדר הגדרות דוא"ל "שולח".',
  'From Email': 'מדוא"ל',
  'From Name': 'משם',
  'Email is required when Name is given.': 'דוא"ל נדרש כאשר ניתן שם.',
  'Invalid locale format. Valid format: `xx-XX`': 'פורמט אזור לא חוקי. פורמט תקף: `xx-XX`',
  'General Settings:': 'הגדרות כלליות:',
  'Contact Address:': 'כתובת איש קשר:',
  'Settings saved': 'הגדרות נשמרו',
  'Customer settings saved': 'הגדרות הלקוח נשמרו',
  'Update meta': 'עדכן מטא',
  'Create zone': 'צור אזור',
  'Delete Zone': 'מחק אזור',
  'No whitespaces allowed': 'אין מרחבים לבנים מותרים',
  'Field cannot be empty': 'השדה לא יכול להיות ריק',
  'Create a store': 'צור חנות',
  'Tax settings updated': 'הגדרות מס עודכנו',
  'Sale taxes updated': 'שיעורי מס עודכנו',
  'Tax group saved': 'קבוצת מס נשמרה',
  'Tax group removed': 'קבוצת מס הוסרה',
  'Enabled': 'מופעל',
  'Min delivery days': 'ימי אספקה מינימליים',
  'Max delivery days': 'מקסימום ימי אספקה',
  'Price type': 'סוג מחיר',
  'Pick Up': 'איסוף',
  'Per item': 'לכל פריט',
  'Fixed': 'קבוע',
  'Loading locations': 'טוען מקומות',
  'API Key': 'מפתח API',
  'Test mode': 'מצב מבחן',
  'Client ID': 'מספר לקוח',
  'Secret Key': 'מפתח סודי',
  'Merchant ID': 'מספר סוחר',
  'Public Key': 'מפתח ציבורי',
  'Private Key': 'מפתח פרטי',
  'Account number': 'מספר חשבון',
  'Paid to account': 'שולם לחשבון',
  'Territory': 'טריטוריה',
  'Administrative Area': 'אזור מינהלי',
  'Locality': 'מקום',
  'Included postal codes': 'מיקוד כלולים',
  'Excluded postal codes': 'מיקוד לא כלולים',
  'Delete territory': 'מחק טריטוריה',
  'Territories': 'טריטוריות',
  'Add new territory': 'הוסף טריטוריה חדשה',
  'Territory saved': 'טריטוריה שמורה',
  'Validation failed': 'נכשל אימות',
  'Zones:': 'אזורים:',
  'No zones to display, create some!': 'אין אזורים להצגה, צור כמה!',
  'Add item to the list': 'הוסף פריט לרשימה',
  'Rules': 'כללים',
  'Write a descriptive name and select a primary action for this <br> filter to execute when the rules below are met':
    'כתוב שם תיאורי ובחר פעולה ראשונית לסינון זה לביצוע כאשר מתקיימים הכללים הבאים',
  'Disabled': 'מושבת',
  'Cancel Order': 'בטל הזמנה',
  '(immediately cancel order that meets the rules below)': '(בטל מיד הזמנה שעומדת בכללים שלהלן)',
  'Warning': 'אזהרה',
  '(warn me on admin email)': '(הזהיר אותי בדוא"ל של מנהל המערכת)',
  'Select appropriate rule(s) to filter the fraudulent activity <br> you are seeing. You can edit these rules at any time.':
    'בחר כלל / ים מתאימים לסינון הפעילות המרמה <br> שאתה רואה. אתה יכול לערוך כללים אלה בכל עת.',
  'The filter will act on order only if': 'המסנן יפעל עבור הזמנה רק אם',
  'of these conditions are match': 'מהתנאים הללו תואמים',
  'Advanced filters': 'מסננים מתקדמים',
  'Options': 'אפשרויות',
  'Pick up a few options for this filter': 'בחר כמה אפשרויות עבור המסנן הזה',
  'Refund transaction amount (if available)?': 'סכום החזר עסקה (אם קיים)?',
  'Send cancelation email?': 'לשלוח אימייל ביטול?',
  'Re-stock product inventory?': 'להוסיף למלאי המוצר?',
  'Fraud Rules': 'כללי הונאה',
  'Checkout validation': 'אימות קופה',
  'Order processing validation': 'אימות עיבוד הזמנות',
  'Admin ID': 'מספר מנהל',
  'Admin name': 'שם מנהל',
  'Contents': 'תוכן',
  'Contents options': 'אפשרויות תוכן',
  'content option items': 'פריטי אפשרות תוכן',
  'equals': 'שווה',
  'not equals': 'לא שווה',
  'greater than': 'גדול מ',
  'lower than': 'נמוך מ',
  'like': 'כמו',
  'BOOL': 'BOOL',
  'DATE': 'תאריך',
  'DATETIME': 'תאריך שעה',
  'Trigger': 'הדק',
  'Blank Page': 'עמוד ריק',
  'Blank page template': 'תבנית דף ריק',
  'Sorry, access denied.': 'מצטערים, הגישה נדחתה.',
  'Go Home': 'לך הביתה',
  'Sorry, the server is down.': 'מצטער, השרת מושבת.',
  'Try Again': 'נסה שוב',
  'Commerce Console': 'קונסולת המסחר',
  'Remember password? Log In!': 'זוכר את הסיסמה? התחבר!',
  'Email is required': 'נדרש אימייל',
  'Password reset link has been sent to your email if there is an account associated with it.':
    'קישור לאיפוס סיסמה נשלח לדוא"ל שלך אם יש חשבון המשויך אליו.',
  'Sorry, page not found': 'סליחה, הדף לא נמצא',
  'Upload': 'העלה',
  'Add Folder': 'הוסף תיקיה',
  'Password changed. You can now log in.': 'הוחלף סיסמה. כעת תוכל להיכנס.',
  'Sorry, data not found': 'מצטערים, לא נמצאו נתונים',
  'Date': 'תאריך',
  'Time': 'שעה',
  'Continue': 'המשיך',
  'Backend connected.': 'Backend מחובר.',
  'Backend disconnected.': 'Backend מנותק.',
  'Logout successfully.': 'התנתק בהצלחה.',
  'No response from the server': 'אין תגובה מהשרת',
  'Something went wrong': 'משהו קרה',
  'User created': '',
  'User updated': '',
  'Delete User': '',
  'Taxonomy saved': '',
  'Shipping methods': '',
  'Documents': 'מסמכים',
  'Created From:': '',
  'Created By:': '',
  'Set your URL': '',
  'or': '',
  'Country code': '',
  'Zone meta info': '',
  'Zone name': '',
  'Zone code': '',
  'Shipping Settings': '',
  'status:': '',
  '(online)': '',
  'When application is being selected, this product will become available only for it <br> becoming automatically unavailable for all applications not being selected. <br> When no application is selected, then product is available for all.':
    '',
  'Payment Settings': '',
  'Taxes Settings': '',
  'Total items amount': '',
  'Total items count': '',
  'Items type': '',
  'Shipping region': '',
  'is': '',
  'not': '',
  'does not equals': '',
  'contains': '',
  'does not contain': '',
  'is empty': '',
  'is not empty': '',
  'begins with': '',
  'ends with': '',
  'paid': '',
  'change': '',
  'Exact': '',
  'Class': 'סוג',
  'Gross Price': 'מחיר ברוטו',
  'Gross Sale Price': 'מחיר ברוטו במבצע',
  'Product classification': 'סיווג מוצרים',
  'Sort order': 'סדר המיון',
  'common': 'רגיל',
  'bundle': 'חבילה',
  'Bundle exclusive': 'בלעדי לחבילות',
  'Extras': 'תוספות',
  'No extras': 'אין תוספות',
  'Plugins': 'תוספים',
  'Notifications': 'התראות',
  'Send Email to Customer': 'שלח דוא"ל ללקוח',
  'Ignore PoS Guests': 'התעלם מאורחי PoS',
  'Total Due': 'סך הכל לתשלום',
  'Total Paid': 'סך הכל שולם',
  'Change': 'עודף',
  'List of disabled notifications or attachments disabled from notifications.':
    'רשימת התראות מושבתות או קבצים מצורפים מושבתים מהודעות.',
  'Disabled Notifications': 'התראות מושבתות',
  'Disabled Attachments': 'קבצים מצורפים לנכים',
  'Disabling notifications features': 'השבתת תכונות התראות',
  'List of notifications types that should be enabled.': 'רשימת סוגי התראות שיש להפעיל.',
  'Enabled Channels': 'ערוצים מופעלים',
  'Notifications List': 'רשימת התראות',
  'Notifications "from"': 'התראות "מאת"',
  'Configure from what address system emails should be send.': 'הגדר מאיזו כתובת יש לשלוח דוא"ל של מערכת.',
  'Emails "from"': 'אימיילים "מאת"',
  'Emails BCC': 'דוא"ל BCC',
  'Configure BCC email and list of templates that should use it.':
    'הגדר את דוא"ל BCC ורשימת התבניות שאמורות להשתמש בו.',
  'BCC Email': 'דוא"ל BCC',
  'Templates list': 'רשימת תבניות',
  'Disabling email notifications': 'השבתת התראות דוא"ל',
  'List of disabled emails notifications or emails attachments.': 'רשימת התראות דוא"ל מושבתות או קבצים מצורפים לדוא"ל.',
  'Disabled Templates': 'תבניות מושבתות',
  'Vonage SMS Credentials': 'אישורי SMS של Vonage',
  'Vonage SMS gateway configuration required for properly working SMS notifications.':
    'תצורת שער ה- SMS של Vonage נדרשת להתראות SMS תקינות.',
  'Key': 'מַפְתֵחַ',
  'Secret': 'סוֹד',
  'SMS "from"': 'SMS "מאת"',
  'Configure from what name/number system SMS messages should be send.':
    'הגדר מאיזה שם / מספר מערכת יש לשלוח הודעות SMS.',
  'From Name/Number': 'מאת שם / מספר',
  'Disabling SMS notifications': 'השבתת התראות SMS',
  'List of disabled SMS notifications.': 'רשימת התראות SMS מושבתות.',
  'MAC Address': 'כתובת MAC',
  'Printers': 'מדפסות',
  'List of allowed printers': 'רשימת מדפסות מותרות',
  'Automatic printing': 'הדפסה אוטומטית',
  'Printing events': 'הדפסת אירועים',
  'List of available printers': 'רשימת המדפסות הזמינות',
  'Documents (invoice/receipt) printing settings': 'הגדרות הדפסת מסמכים (חשבונית / קבלה)',
  'Configure StarPrinter integration for automated documents printing.':
    'הגדר את שילוב StarPrinter להדפסת מסמכים אוטומטית',
  'Slips': 'מחליק',
  'Invalid MAC address': 'כתובת MAC לא חוקית',
  'Footer printed with every document': 'כותרת תחתונה המודפסת עם כל מסמך',
  'Deleting records.': 'מחיקת רשומות',
  'Languages': 'שפות',
  'Here you can setup taxes': 'כאן תוכלו להגדיר מיסים',
  'Default Language': 'שפת ברירת מחדל',
  'Default store language selection': 'בחירת שפת חנות המוגדרת כברירת מחדל',
  'general.settings.locale': 'המקום נמצא בשימוש עבור עיצוב תאריכים ומטבעו.',
  'general.settings.language': 'שפת ברירת המחדל קובעת באילו תכונות בסיס שפה מוגדרים של מוצרים, שיטות משלוח ואחרות.',
  'general.settings.language.change': 'שינוי שפת ברירת המחדל לשפה שונה לא יחליף תרגומים לכל הגופים בחנות.',
  'This change will only determine in what language current attributes are defined leaving them always as default.':
    'שינוי זה יקבע רק באיזו שפה מוגדרים תכונות נוכחיות ומשאיר אותם תמיד כברירת מחדל.',
  'general.settings.language.change.example':
    'לדוגמא, כאשר שפת ברירת המחדל המקורית הוגדרה לאנגלית והיא תשונה לספרדית, כל הגופים יישארו עם מאפייני בסיס באנגלית ויהיה עליהם לשנות לספרדית.',
  'List of languages available for translations': 'רשימת השפות הזמינות לתרגומים',
  'Direction': 'כיוון',
  'Locale': 'אזור',
  'Type to create': 'הקלד כדי ליצור',
  'Default language saved': 'שפת ברירת המחדל נשמרה',
  'Failed to save default language': 'שמירת שפת ברירת המחדל נכשלה',
  'At least one language needs to be defined': 'צריך להגדיר לפחות שפה אחת',
  'Cannot remove default language': 'לא ניתן להסיר את שפת ברירת המחדל',
  'Language created': 'שפה נוצרה',
  'Are you sure you want to delete language?': 'האם אתה בטוח שברצונך למחוק את השפה?',
  'Language deleted': 'שפה נמחקה',
  'Flag': 'דֶגֶל',
  'Locale ISO': 'מקומי ISO',
  'Reset Inventory': 'אפס מלאי',
  'Store created successfully.': 'החנות נוצרה בהצלחה.',
  'Store creation processes may take a while and some things may appear with a delay.':
    'תהליכי יצירת חנויות עשויים לארוך זמן מה וחלק מהדברים עשויים להופיע בעיכוב.',
  'Invalid URL format': 'פורמט כתובת URL לא חוקי',
  'Base Info': 'מידע בסיסי',
  'Previous': 'קודם',
  'Next': 'הַבָּא',
  'Store deleted': 'החנות נמחקה',
  'Payment method deleted': 'אמצעי התשלום נמחק',
  'Automatically Pay': 'שלם אוטומטית',
  'Add payment method': 'הוסף אמצעי תשלום',
  'List of available system payment methods.': 'רשימת אמצעי תשלום מערכת זמינים.',
  'Send SMS to Customer': 'שלח SMS ללקוח',
  'SMS Prefix': 'קידומת SMS',
  'SMS Suffix': 'סיומת SMS',
  'Buzz on print': 'זמזם',
  'Open cash drawer on print': 'פתח מגירת מזומנים בהדפסה',
  'Provider': 'ספק',
  'Transaction type': 'סוג עסקה',
  'Payment type': 'סוג תשלום',
  'Payment transactions': 'עסקאות תשלום',
  'New': 'חָדָשׁ',
  'Pending': 'ממתין ל',
  'Finished': 'גָמוּר',
  'Error': 'שְׁגִיאָה',
  'Canceled': 'מבוטל',
  'Amount': 'כמות',
  'Dimensions': 'ממדים',
  'Lenght': 'אורך',
  'Width': 'רוחב',
  'Height': 'גובה',
  'Weight': 'משקל',
  'Size Unit': 'יחידת גודל',
  'Weight Unit': 'יחידת משקל',
  'Update template settings': 'עדכן את הגדרות התבנית',
  'Default template': 'תבנית ברירת מחדל',
  'Pages': 'עמודים',
  'Page name': 'כותרת העמוד',
  'Protected': 'מוגן',
  'Add new page': 'הוסף דף חדש',
  'Blocks': 'בלוקים',
  'Block name': 'שם בלוק',
  'Template': 'תבנית',
  'Content': 'תוֹכֶן',
  'Scripts': 'תסריטים',
  'Available variables': 'משתנים זמינים',
  'The HTML and Scripts fields support the': 'השדות HTML ו-Scripts תומכים ב-',
  'mustache template syntax': 'תחביר תבנית שפם',
  'Insert text here': 'הוסף טקסט כאן',
  'Import template': 'ייבוא תבנית',
  'Export template': 'ייצוא תבנית',
  'Import file': 'ייבוא קובץ',
  'Import full template': 'ייבא תבנית מלאה',
  'Select no if you want make partials import': 'בחר לא אם ברצונך לבצע ייבוא חלקים',
  'Overwrite data': 'החלף נתונים',
  'Select import parts': 'בחר חלקי ייבוא',
  'yes': 'כן',
  'no': 'לא',
  'pages': 'דפים',
  'blocks': 'בלוקים',
  'settings': 'הגדרות',
  'Preset': 'מוגדר מראש',
  'Import preset': 'ייבוא הגדרה מראש',
  'Export preset': 'ייצוא מוגדר מראש',
  'Requested delivery date': 'תאריך משלוח מבוקש',
  'Delivery date': 'תאריך משלוח',
  'Delivery time': 'זמן משלוח',
  'Should be visible for customer': 'אמור להיות גלוי ללקוח',
  'Update comment': 'עדכן תגובה',
  'Create comment': 'צור תגובה',
  'store.wizard.custom.domain': '',
  'The Sales Representative user may need special permissions to create orders on behalf of customers. Make sure he has such':
    'ייתכן שמשתמש נציג המכירות יזדקק להרשאות מיוחדות כדי ליצור הזמנות בשם הלקוחות. תוודא שיש לו כאלה',
  'Commissions': 'עמלות',
  'Approve': 'לְאַשֵׁר',
  'Unapprove': 'בטל אישור',
  'Revoke': 'לְבַטֵל',
  'Recalculate': 'חישוב מחדש',
  'Commission recalculation': 'חישוב מחדש של עמלה',
  'Percentage': 'אֲחוּזִים',
  'Commission on tax': 'ועדת מס',
  'Commission on shipping': 'עמלה על משלוח',
  'Commission was approved': 'הוועדה אושרה',
  'Commission was unapproved': 'הוועדה לא אושרה',
  'Commissions was approved': 'הוועדות אושרו',
  'Commissions was unapproved': 'העמלות לא אושרו',
  'Commission was revoked': 'הוועדה בוטלה',
  'Associated customers': 'לקוחות קשורים',
  'Access to all customers': 'גישה לכל הלקוחות',
  'No access to customers': 'אין גישה ללקוחות',
  'Purchase order': 'הזמנה',
  'Consider making a copy of the template before installation as it will replace your current template.':
    'שקול ליצור עותק של התבנית לפני ההתקנה מכיוון שהיא תחליף את התבנית הנוכחית שלך.'
}
