import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentLanguage)?_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"depressed":"","icon":""}},'v-btn',attrs,false),on),[_c('flag',{attrs:{"iso":_vm.currentLanguage.flag}})],1)]}}],null,false,862254215)},[_c(VList,_vm._l((_vm.languages),function(item,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.setLanguage(item)}}},[_c(VListItemIcon,[_c('flag',{attrs:{"iso":item.flag}})],1),_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")])],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }