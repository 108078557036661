export default {
  // Test
  'Console': 'Le Console',

  // Menu
  'Carts': 'Panier',
  'Orders': 'Commandes',
  'Customers': 'Clients',
  'Products': 'Produits',
  'Disconnected': 'Débranché',
  'My Account': 'Mon compte',
  'Dark': 'Sombre',
  'Light': 'Clair',
  'Logout': 'Se déconnecter',

  // Settings
  'Configuration for invoices, receipts and credit memos': 'Configuration des factures, des reçus et des avoirs',
  'Update template settings': 'Mettre à jour les paramètres du modèle',
  'Default template': 'Modèle par défaut',
  // Common
  'Point of Sale': 'Point de Vente',
  'Customer': 'Client',
  'Simple': 'Facile',
  'Labeled': 'Etiqueté',
  'Confirm': 'Confirmer',
  'Subtotal': 'Sous-total',
  'Discounts': 'Réductions',
  'Taxes': 'Taxes',
  'Total': 'Total',
  'Order': 'Commande',
  'Sort order': 'Ordre de tri',
  'E-mail': 'Email',
  'Shipping Address': 'Adresse de Livraison',
  'Billing Address': 'Adresse de Facturation',
  'Save': 'Sauver',
  'Reset': 'Réinitialiser',
  'Summary': 'Résumé',
  'Delete': 'Supprimer',
  'Update': 'Mettre à jour',
  'Items': 'Articles',
  "Yes, that's fine": "Oui c'est bon",
  'Danger Zone': 'Zone dangereuse',
  'Please Confirm': 'Veuillez confirmer',
  'Price': 'Prix',
  'Sale Price': 'Prix de Vente',
  'Yes': 'Oui',
  'No': 'Non',
  'Add': 'Ajouter',
  'Submit': 'Soumettre',
  'Created From': 'Créé à partir de',
  'Creation Source': 'Source de création',
  'General': 'Général',
  'Footer': 'Bas de page',

  // ToS
  'I have read and agree to the FutureEcom': "J'ai lu et j'accepte le FutureEcom",
  'Terms of Service': "Conditions d'utilisation",

  // Credentials
  'Login': "S'identifier",
  'Password': 'Mot de passe',
  'Forgot Password?': 'Mot de passe oublié?',
  'Remember password? Log In!': "Se souvenir du mot de passe? S'identifier!",
  'Send Reset Link': 'Envoyer le lien de réinitialisation',
  'New Password': 'Nouveau mot de passe',
  'Reset Password': 'Réinitialiser le mot de passe',
  'Password Confirmation': 'Confirmation mot de passe',
  'Account saved': 'Compte enregistré',

  // Customer Info
  'First Name': 'Prénom',
  'Last Name': 'Nom de famille',
  'Date of Birth': 'Date de naissance',
  'Date Of Birth': 'Date de naissance',
  'Telephone': 'Téléphone',
  'Street': 'Rue',
  'City': 'Ville',
  'State': 'Province',
  'Country': 'Pays',
  'Post code': 'Code postal',
  'Company': 'Compagnie',

  // Product Info
  'Available': 'Disponible',
  'Availability': 'Disponibilité',
  'Min per order': 'Min par commande',
  'Max per order': 'Min par commande',
  'In Stock': 'En stock',
  'Policy': 'Politique',
  'Quantity': 'Quantité',
  'continue': 'continuer',
  'stop': 'Arrêtez',
  'Track Inventory': "Suivre l'inventaire",
  'Restockable Item': 'Article réapprovisionnable',
  'Product Type': 'Type de produit',
  'physical': 'physique',
  'digital': 'digitale',
  'downloadable': 'téléchargeable',

  // Dashboard
  'Create Order': 'Créer une commande',

  // Page Titles
  'Quotes': 'Devis',

  // Action Buttons
  'Create': 'Créer',
  'Create Quote': 'Créer un devis',
  'Place Order': 'Passer la commande',
  'Hold': 'Tenir',
  'Invoice': "Facture d'achat",
  'Add new Customer': 'Ajouter un nouveau client',
  'Cancel': 'Annuler',
  'Release': 'Libération',

  // Cart Creation Page
  'All': 'Tout',
  'All Categories': 'Toutes catégories',
  'Note Type': 'Type de note',
  'Add Note': 'Ajouter une note',
  'Simple Note': 'Note simple',
  'Label': 'Étiquette',
  'Values': 'Valeurs',
  'Add Product': 'Ajouter un produit',
  'Added Items': 'Éléments ajoutés',
  'Note': 'Remarque',
  'Shipping Method': 'Mode de livraison',
  'Coupon': 'Coupon',
  'Code': 'Code',
  'Add Coupon': 'Ajouter un coupon',
  'Apply': 'Appliquer',
  'Applied': 'Appliqué',
  'Variants': 'Variantes',
  'Edit': 'Éditer',
  'Search Products by name': 'Rechercher des produits par nom',
  'Details': 'Détails',
  'Set As Guest': 'Définir comme invité',
  'Search by last name': 'Recherche par nom de famille',
  'Create Customer and Save': 'Créer un client et enregistrer',
  'Fill Address': "Remplir l'adresse",
  'productsPagination': '{page} de {total}', // replace "of"
  'Coupon Code': 'Code coupon',
  'Nothing to show': 'Rien à afficher',
  'Customer Details': 'Détails du client',
  'Order Note': 'Note de commande',
  'Properties': 'Propriétés',
  'Product': 'Produit',
  'Select Payment Method': 'Sélectionnez le mode de paiement',
  'Place': 'Endroit',
  'Set Method': 'Définir la méthode',
  'Required!': 'Obligatoire!',
  'Notes': 'Remarques',
  'No products to show': 'Aucun produit à afficher',
  'Draft Order Saved': 'Commande brouillon enregistrée',
  'Cannot find order created from this Cart. Redirecting to Orders list.':
    'Impossible de trouver la commande créée à partir de ce panier. Redirection vers la liste des commandes.',
  'There are no shipping methods available.': "Aucune méthode d'expédition n'est disponible.",
  'Please choose a variant': 'Veuillez choisir une variante',

  // Order Page
  'Refund': 'Rembourser',
  'Post': 'Publier',
  'Status': 'Statut',
  'Archive': 'Archiver',
  'Ship Items': 'Expédier les articles',
  'Mark as Paid': 'Marquer comme payé',
  'guest': 'invité',
  'processing': 'En traitement',
  'approved': 'approuvé',
  'shipped': 'Expédié',
  'partially shipped': 'partiellement expédié',
  'on_hold': 'en attente',
  'refunded': 'remboursé',
  'canceled': 'annulé',
  'completed': 'terminé',
  'unfulfilled': 'non satisfait',
  'Leave a comment...': 'Laissez un commentaire...',
  'Unit Price': 'Prix unitaire',
  'Row Total': 'Ligne Total',
  'Hold Reasons': 'Maintenir les raisons',
  'Guest': 'Invité',
  'Payment Method': 'Mode de paiement',
  'Payment from': 'Paiement de',
  'via': 'via',
  'Timeline': 'Chronologie',
  'Shipment': 'Expédition',
  'Carrier': 'Transporteur',
  'Tracking number': 'Numéro de suivi',
  'Please select items to refund': 'Veuillez sélectionner les articles à rembourser',
  'Please select refund options': 'Veuillez sélectionner les options de remboursement',
  'Notify Customer': 'Notifier le client',
  'Re-stock items': 'Réapprovisionner les articles',
  'Refund Items': 'Rembourser les articles',
  'Refund order': 'Commande de remboursement',
  'Added shipment': 'Expédition ajoutée',
  'Order marked as paid': 'Commande marquée comme payée',
  'Put on hold': 'Mettre en attente',
  'Are you sure that you want to mark this order as paid?':
    'Êtes-vous sûr de vouloir marquer cette commande comme payée?',
  'markOrderAsPaid': 'Marquer la commande n ° #{order} comme payée',
  'Hold Reason': 'Maintenir la raison',
  'Please write the hold reason': 'Veuillez écrire la raison de la suspension',
  'Created By': 'Créé par',
  'Resolved At': 'Résolu à',
  'Mark order {0} as paid': 'Marquer la commande n ° #{0} comme payée',
  'items': 'articles',
  'Shipping method': 'Mode de livraison',
  '{0} items': '{0} articles',

  // Product Page
  'unlimited': 'illimité',
  'Inventory': 'Inventaire',
  'Variants Options': 'Options des variantes',
  'Images': 'Images',
  'Slug': 'Slug',
  'Taxonomy': 'Taxonomie',
  'Taxonomy settings': 'Paramètres de taxonomie',
  'Brand': 'Marque',
  'Description': 'La description',
  'Tags': 'Mots clés',
  'Product Variations': 'Variations de produit',
  'Search Variation': 'Variante de recherche',
  'No properties': 'Aucune propriété',
  'No Variants': 'Aucune variante',
  'Policy (allows customer to purchase out of stock item)':
    "Politique (permet au client d'acheter un article en rupture de stock)",
  'Bundle': 'Paquet',
  'Bundle Components': 'Composants du bundle',
  'Add components': 'Ajouter des composants',
  'Bundle exclusive': 'Pack exclusif',

  // Customer Page
  'Personal Information': 'Renseignements personnels',
  'Customer Group': 'Groupe de clients',
  'New Customer': 'Nouveau client',
  'Subscription': 'Abonnement',
  'Group': 'Groupe',
  'Addresses': 'Adresses',
  'Search Address': 'Adresse de recherche',
  'Add New': 'Ajouter nouveau',
  'No orders yet for this customer': 'Pas encore de commande pour ce client',
  'Default': 'Défaut',

  // Invoice Page
  'View Order': 'Voir la commande',
  'Place Another Order': 'asser une autre commande',
  'Print Invoice': 'Imprimer la facture',
  'INVOICE NO.': 'N ° FACTURE',
  'ORDER DATE': 'DATE DE COMMANDE',
  'PAYMENT': 'PAIEMENT',
  'SHIPPING': 'LIVRAISON',
  'BILL TO': 'FACTURER',
  'SHIP TO': 'ENVOYEZ À',
  'ITEM DESCRIPTION': "DESCRIPTION DE L'ARTICLE",
  'QTY': 'QTY',
  'PRICE': 'PRIX',
  'TOTAL': 'TOTAL',
  'Customer Note': 'Note client',
  'Discount': 'Remise',
  'Sales Taxes': 'Taxes de vente',
  'Shipping': 'Livraison',
  'Thanks for choosing our products!': "Merci d'avoir choisi nos produits!",

  // Grids
  'Number': 'Nombre',
  'Name': 'Nom',
  'Items count': "Nombre d'articles",
  'ID': 'ID',
  'Order Status': 'Statut de la commande',
  'Created At': 'Créé à',
  'Email': 'Email',
  'DOB': 'Date de naissance',
  'Phone': 'Téléphone',
  'Image': 'Image',
  'SKU': 'SKU',
  'Counts': 'Compte',
  'Created at': 'Créé à',
  'Rows per page': 'Lignes par page',
  'dataTablePagination': '{start} - {stop} de {total}',
  'Select filter': 'Sélectionner le filtre',
  'Filters': 'Filtres',
  'Brands': 'Marques',
  'Price from low to high': 'Prix de faible à élevé',
  'Price from high to low': 'Prix du plus cher au moins cher',
  'Name (A-Z)': 'Nom (A-Z)',
  'Name (Z-A)': 'Nom (Z-A)',
  'Newest': 'Plus récent',
  'Search cart by user name': "Rechercher un panier par nom d'utilisateur",
  'Search customer': 'Rechercher un client',
  'Search order by ID': 'Recherche par ID',
  'Search product': 'Rechercher un produit',
  'Sort by': 'Trier par',
  'Email (A-Z)': 'E-mail (A-Z)',
  'Email (Z-A)': 'E-mail (Z-A)',
  'Close filters': 'Fermer les filtres',
  '{0} - {1} of {2}': '{0} - {1} de {2}',
  'order.transaction.void': '',
  'Buzz on print': 'Sonore',
  'Open cash drawer on print': 'Ouvrir le tiroir-caisse',
  'Lenght': 'Longueur',
  'Width': 'Largeur',
  'Height': 'Hauteur',
  'Weight': 'Lester',
  'Size Unit': 'Unité de taille',
  'Weight Unit': 'Unité de poids',
  'Pages': 'Page',
  'Page name': 'Titre de la page',
  'Protected': 'Protégé',
  'Updated At': 'Mis à jour à',
  'Add new page': 'Ajouter une nouvelle page',
  'Blocks': 'Bloquers',
  'Block name': 'Nom du bloquer',
  'Template': 'Modèle',
  'Content': 'Teneur',
  'Scripts': 'Script',
  'Available variables': 'Variables disponibles',
  'The HTML and Scripts fields support the': 'Les champs HTML et Scripts prennent en charge les',
  'mustache template syntax': 'syntaxe du modèle de moustache',
  'Insert text here': 'Insérez le texte ici',
  'Import template': 'Importer un modèle',
  'Export template': 'Exporter le modèle',
  'Import file': 'Importer le fichier',
  'Import full template': 'Importer le modèle complet',
  'Select no if you want make partials import': 'Sélectionnez non si vous voulez importer des partiels',
  'Overwrite data': 'Ecraser les données',
  'Select import parts': 'Sélectionner les pièces à importer',
  'yes': 'oui',
  'no': 'non',
  'pages': 'page',
  'blocks': 'bloquers',
  'settings': 'réglages',
  'Preset': 'Préréglé',
  'Import preset': "Préréglage d'importation",
  'Export preset': "Préréglage d'importation",
  'The Sales Representative user may need special permissions to create orders on behalf of customers. Make sure he has such':
    "L'utilisateur Représentant des ventes peut avoir besoin d'autorisations spéciales pour créer des commandes au nom des clients. Assurez-vous qu'il a un tel",
  'Requested delivery date': 'Date de livraison requise',
  'Delivery date': 'Date de livraison',
  'Delivery time': 'Heure de livraison',
  'Should be visible for customer': 'Devrait être visible pour le client',
  'Update comment': 'Mettre à jour le commentaire',
  'Create comment': 'Créer un commentaire',
  'Purchase order': 'Bon de commande',
  'Commissions': 'Commissions',
  'Approve': 'Approuver',
  'Unapprove': "Annuler l'approbation",
  'Revoke': 'Révoquer',
  'Recalculate': 'Recalculer',
  'Commission recalculation': 'Recalcul de la commission',
  'Percentage': 'Pourcentage',
  'Commission on tax': "Commission sur l'impôt",
  'Commission on shipping': "Commission sur l'expédition",
  'Commission was approved': 'Commission a été approuvée',
  'Commission was unapproved': "La commission n'a pas été approuvée",
  'Commissions was approved': 'Les commissions ont été approuvées',
  'Commissions was unapproved': "Les commissions n'ont pas été approuvées",
  'Commission was revoked': 'La commission a été révoquée',
  'Associated customers': 'Clients associés',
  'Access to all customers': 'Accès à tous les clients',
  'No access to customers': "Pas d'accès aux clients",
  'Consider making a copy of the template before installation as it will replace your current template.':
    "Envisagez de faire une copie du modèle avant l'installation car il remplacera votre modèle actuel."
}
